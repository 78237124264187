import { ErrorEventModel, Meta } from "types/shared";
import { MontaOrder } from "./montaOrder";
import {Rma} from "../rma";

export interface SalesOrder {
    mongoId: string;

    reference: References;
    customer: Customer;
    shipping: ShippingData;
    billing: BillingData;
    store: StoreEnum;
    flags: FlagsData;

    items: SalesOrderItem[];

    errorEvents: ErrorEventModel[];
    isNeedsAttention: boolean;

    meta: Meta;
}

export enum StoreEnum {
    All = 0,
    ProfortoNL = 1,
    ProfortoBE = 2,
    ProfortoBol = 4,
    EmmaFootwear = 5,
    Tricorpstore = 6,
}

export interface Customer {
    firstName: string;
    lastName: string;
    email: string;
    mageId?: string;
    phoneNumber?: string;
}

export interface References {
    mage: string;
    bol?: string;
    monta?: string[];
}

export interface Address {
    street: string
    houseNumber: string
    houseNumberAddition: string | null
    postalCode: string
    city: string
    countryCode: string
    companyName?: string
    firstName: string
    lastName: string
}

export interface ShippingData {
    address: Address;
    method: string; // TODO: to enum
    pickupLocationCode?: string;
}

export interface BillingData {
    address: Address;
    paymentMethod: string;

    price: SalesOrderPrice;
}

export interface SalesOrderItem {
    product: {
        sku: string;
        ean: string | null;
        name: string;
        supplierMontaCode: string;
    }

    quantity: number;

    urlKey: string;
    thumbnail: string;

    price: SalesOrderItemPrice;
    discount: SalesOrderItemPrice;

    supplierExactCode: string | null;
    parentItemId: number | null;

    forecastedDeliveryDate: DeliveryDate | null;

    personalisation: PersonalisationData | null
    isPromo: boolean;
}

export interface PersonalisationData {
    type: PersonalisationTypeEnum;
    positions: PersonalisationPosition[];
}

export enum PersonalisationTypeEnum {
    None = 0,
    Printing = 1,
    Embroidery = 2,
    Both = 3,
}

export interface PersonalisationPosition {
    type: PersonalisationPositionEnum;
    name: string;
    imageName: string | null;
    imageUrl: string | null;
}

export enum PersonalisationPositionEnum {
    headFront = 0,
    headBack = 1,
    chestLeft = 2,
    chestCenter = 3,
    chestRight = 4,
    backUpper = 5,
    backCenter = 6,
    legLeft = 7,
    legRight = 8,
    legBackPocketLeft = 9,
    legBackPocketRight = 10,
    armLeft = 11,
    armRight = 12
}

export interface SalesOrderPrice {
    totalPrice: {
        value: string;
    }
    discount: {
        value: string
    }
}

export interface SalesOrderItemPrice {
    value: number;
    valueInCents: number;
}

export interface FlagsData {
    isPersonalized: boolean;
    isConsolidatedShipping: boolean;
}

export interface DeliveryDate {
    sku: string;
    errorMessage: string | null;

    personalisation: PersoEstimation | null
    purchase: Estimation | null;
    warehouse: Estimation | null;
}

export interface PersoEstimation {
    embroidery: Estimation | null;
    printing: Estimation | null;
}

export interface Estimation {
    date: string | null;
    amount: number;
    stockAvailable: boolean;
    errorMessage: string | null;
}


export interface SalesOrderView {
    salesOrder: SalesOrder;
    montaOrders: MontaOrder[];
    rmas: Rma[];
}

export interface MontaOrderEvent {
    eventId: number;
    webshopOrderId: string;
    typeCode: string;
    typeId: number;
    description: string;

    montaOccured: Date;
    montaCreated: Date;
}

export interface SalesOrderSearchCriteria {
    mageOrderId: string | null;
    criteria: string | null;
    sku: string | null;
    startDate: string | null;
    endDate: string | null;
    storeEnum: StoreEnum | null;
    hasPersonalisation: boolean | null;
    isNeedsAttention: boolean;
    page: number;
}


export function personalisationTypeEnumToLabel(type: PersonalisationTypeEnum): string {
    switch (type) {
        case PersonalisationTypeEnum.Printing:
            return "Printing";
        case PersonalisationTypeEnum.Embroidery:
            return "Embroidery";
        case PersonalisationTypeEnum.Both:
            return "Both";
        case PersonalisationTypeEnum.None:
            return "None";

        default:
            return "";
    }
}

export function addressEquals(a: Address, b: Address): boolean {
    return a.street === b.street
        && a.houseNumber === b.houseNumber
        && a.houseNumberAddition === b.houseNumberAddition
        && a.city === b.city
        && a.postalCode === b.postalCode
        && a.countryCode === b.countryCode;
}
